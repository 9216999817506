import React, { forwardRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { RiArrowDropDownLine, RiCloseFill, RiMenuLine } from "react-icons/ri";
import navigationItems from "../../utils/constants/NavigationItems";

import "./Headers.scss";
import withClickOutSideMobile from "./withClickOutSideMobile";
// import SidebarMenu from "./SidebarMenu";
import { useLogout } from "../../hooks/useLogout";
import {
  useGetDashboardMenuStatus,
  useGetNurseByUserId,
} from "../../hooks/useNurse";

const ProviderMobileMenu = forwardRef(({ open, setOpen }, ref) => {
  const [sideOpen, setSideOpen] = useState(false);

  const nurseID = localStorage.getItem("nurseID") || "";

  const location = useLocation();
  const navigation = useNavigate();

  const { refetch, data: logoutData } = useLogout();

  const { data: menuData } = useGetDashboardMenuStatus();
  const { data: nurseData } = useGetNurseByUserId(nurseID);

  const isUserAuthenticated = Boolean(nurseID);
  const playAudio = (url) => {
    if (!url) {
      console.error("Audio URL is not defined.");
      return;
    }
    const audio = new Audio(url);
    audio.volume = 0.9;
    audio.play().catch(() =>
      fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buffer) => {
          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          audioContext.decodeAudioData(buffer).then((audioBuffer) => {
            const source = audioContext.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(audioContext.destination);
            source.start(0);
          });
        })
        .catch((err) => console.error("Audio playback failed:", err))
    );
  };

  const notificationSounds = {
    talkToNurse: process.env.REACT_APP_ALERT_NOTIFICATION,
    emergencyCare: process.env.REACT_APP_ALERTU_NOTIFICATION,
    talkToDoc: process.env.REACT_APP_TALKDOC_NOTIFICATION,
  };

  const playNotificationSound = (type) => {
    playAudio(notificationSounds[type]);
  };

  const restrictedPaths = ["/nurse-service-details", "/nurse-consultation"];
  const shouldPlaySound = !restrictedPaths.some((path) =>
    location.pathname.includes(path)
  );

  useEffect(() => {
    const currentStatus = menuData?.agentDashboardMenuStatus || {};

    const playSounds = () => {
      if (shouldPlaySound) {
        if (currentStatus.talkToNurseReq > 0) {
          playNotificationSound("talkToNurse");
        }
        if (currentStatus.emergencyCareReq > 0) {
          playNotificationSound("emergencyCare");
        }
        if (currentStatus.talkToDocReq > 0) {
          playNotificationSound("talkToDoc");
        }
      }
    };

    // Play sounds immediately if conditions are met
    playSounds();

    // Set interval for every 2 minutes
    const interval = setInterval(playSounds, 120000);

    return () => clearInterval(interval); // eslint-disable-next-line
  }, [menuData?.agentDashboardMenuStatus, location.pathname, shouldPlaySound]);

  useEffect(() => {
    if (nurseData) {
      localStorage.setItem("nurseUn", nurseData?.agentDetails?.firstname);
    }
  }, [nurseData]);

  const logout = () => {
    refetch();
    if (logoutData?.status === "SUCCESS") {
      localStorage.removeItem("auth");
    }
    localStorage.removeItem("userID");
    localStorage.removeItem("providerId");
    localStorage.removeItem("clinicId");
    localStorage.removeItem("auth");
    localStorage.removeItem("expires_in");
    localStorage.removeItem("isSCAdmin");
    localStorage.removeItem("refresh_token");
    navigation("/login");
    setOpen(!open);
  };
  const handleMainNavClick = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (open === false) {
      setSideOpen(false);
    }
  }, [open]);
  return (
    <>
      {isUserAuthenticated ? (
        <div className="navbar" ref={ref}>
          <div className="navbar__left">
            <div className="navbar__logo">
              <img
                src="https://static.meragoapp.com/images/menuLogo.png"
                alt="logo"
                width="55px"
                height="55px"
              />
            </div>
          </div>

          <div className="mobile__hamburger" onClick={handleMainNavClick}>
            {!open ? <RiMenuLine /> : <RiCloseFill />}
          </div>
          {open && (
            <div className="mobile__main__menu">
              <div className="main__menu" onClick={() => setOpen(false)}>
                <Link
                  to="talk-nurse"
                  className={
                    location.pathname.includes("/talk-nurse")
                      ? "sidebar_active"
                      : ""
                  }
                >
                  {menuData?.agentDashboardMenuStatus?.talkToNurseReq > 0 && (
                    <div className="red__alert"></div>
                  )}
                  Home
                </Link>
                <Link
                  to="nurse-dashboard"
                  className={
                    location.pathname.includes("/nurse-dashboard")
                      ? "sidebar_active"
                      : ""
                  }
                >
                  {(menuData?.agentDashboardMenuStatus?.emergencyCareReq > 0 ||
                    menuData?.agentDashboardMenuStatus?.talkToDocReq > 0) && (
                    <div className="red__alert"></div>
                  )}
                  Nurse Care
                </Link>
              </div>
              <div
                className="mobile__name__side"
                onClick={() => setSideOpen(!sideOpen)}
              >
                <p>
                  {nurseData?.agentDetails?.firstname}{" "}
                  {nurseData?.agentDetails?.lastname}
                </p>
                <RiArrowDropDownLine />
              </div>
            </div>
          )}
          {sideOpen ? (
            <div className="sidebar__conatiner">
              {/* <SidebarMenu
                setOpen={setOpen}
                open={open}
                providerData={providerData?.provider}
                clinicData={clinicData}
                profileCompletionData={profileCompletionData}
              /> */}
              {location.pathname !== "/login" && (
                <button onClick={logout}>Logout</button>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="navbar__login__screen">
          <div className="navbar__logo">
            <img
              src="https://static.meragoapp.com/images/meragoHealth.png"
              alt="logo"
              width="155px"
              height="55px"
            />
          </div>
          {!location?.pathname?.includes("guest") && (
            <div className="navbar__single">
              {navigationItems.loginMenu.map((items) => (
                <Link
                  key={items.text}
                  to={items.to}
                  className={
                    location.pathname.includes(items.to) ? "navbar_active" : ""
                  }
                >
                  {items.name}
                </Link>
              ))}
            </div>
          )}
        </div>
      )}{" "}
    </>
  );
});

export default withClickOutSideMobile(ProviderMobileMenu);
