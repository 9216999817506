import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "../../../hooks/useLogout";
import assets from "../../../utils/Assets";
import withOutsideClickNurse from "./withOutsideClickNurse";
import {
  useGetDashboardMenuStatus,
  useGetNurseByUserId,
} from "../../../hooks/useNurse";

const NurseMenu = React.forwardRef(({ setOpen, open }, ref) => {
  const { refetch, data: logoutData } = useLogout();
  const location = useLocation();
  const navigate = useNavigate();
  const nurseID = localStorage.getItem("nurseID") || "";

  const { data: menuData } = useGetDashboardMenuStatus();
  const { data: nurseData } = useGetNurseByUserId(nurseID);

  const isUserAuthenticated = Boolean(nurseID);

  const playAudio = (url) => {
    if (!url) {
      console.error("Audio URL is not defined.");
      return;
    }
    const audio = new Audio(url);
    audio.volume = 0.9;
    audio.play().catch(() =>
      fetch(url)
        .then((res) => res.arrayBuffer())
        .then((buffer) => {
          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          audioContext.decodeAudioData(buffer).then((audioBuffer) => {
            const source = audioContext.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(audioContext.destination);
            source.start(0);
          });
        })
        .catch((err) => console.error("Audio playback failed:", err))
    );
  };

  const notificationSounds = {
    talkToNurse: process.env.REACT_APP_ALERT_NOTIFICATION,
    emergencyCare: process.env.REACT_APP_ALERTU_NOTIFICATION,
    talkToDoc: process.env.REACT_APP_TALKDOC_NOTIFICATION,
  };

  const playNotificationSound = (type) => {
    playAudio(notificationSounds[type]);
  };

  const restrictedPaths = ["/nurse-service-details", "/nurse-consultation"];
  const shouldPlaySound = !restrictedPaths.some((path) =>
    location.pathname.includes(path)
  );

  useEffect(() => {
    const currentStatus = menuData?.agentDashboardMenuStatus || {};

    const playSounds = () => {
      if (shouldPlaySound) {
        if (currentStatus.talkToNurseReq > 0) {
          playNotificationSound("talkToNurse");
        }
        if (currentStatus.emergencyCareReq > 0) {
          playNotificationSound("emergencyCare");
        }
        if (currentStatus.talkToDocReq > 0) {
          playNotificationSound("talkToDoc");
        }
      }
    };

    // Play sounds immediately if conditions are met
    playSounds();

    // Set interval for every 2 minutes
    const interval = setInterval(playSounds, 120000);

    return () => clearInterval(interval); // eslint-disable-next-line
  }, [menuData?.agentDashboardMenuStatus, location.pathname, shouldPlaySound]);

  useEffect(() => {
    if (nurseData) {
      localStorage.setItem("nurseUn", nurseData?.agentDetails?.firstname);
    }
  }, [nurseData]);

  const handleLogout = () => {
    refetch();
    if (logoutData?.status === "SUCCESS") {
      [
        "nurseID",
        "nurseAuth",
        "expires_in",
        "nurse_refresh_token",
      ].forEach((key) => localStorage.removeItem(key));
      navigate("/login");
      setOpen(false);
    }
  };

  return isUserAuthenticated ? (
    <div className="navbar" ref={ref}>
      <div className="navbar__left">
        <div className="navbar__logo">
          <img
            src="https://static.meragoapp.com/images/menuLogo.png"
            alt="logo"
            width="55"
            height="55"
          />
        </div>
        <div className="navbar__items nav__more__left">
          {!location.pathname.includes("/nurse-consultation") && (
            <>
              <Link
                to="talk-nurse"
                className={
                  location.pathname.includes("/talk-nurse")
                    ? "sidebar_active"
                    : ""
                }
              >
                {menuData?.agentDashboardMenuStatus?.talkToNurseReq > 0 && (
                  <div className="red__alert"></div>
                )}
                Home
              </Link>
              <Link
                to="nurse-dashboard"
                className={
                  location.pathname.includes("/nurse-dashboard")
                    ? "sidebar_active"
                    : ""
                }
              >
                {(menuData?.agentDashboardMenuStatus?.emergencyCareReq > 0 ||
                  menuData?.agentDashboardMenuStatus?.talkToDocReq > 0) && (
                  <div className="red__alert"></div>
                )}
                Nurse Care
              </Link>
            </>
          )}
        </div>
      </div>
      <div className="sideBar__main" onClick={() => setOpen(!open)}>
        <p>
          {nurseData?.agentDetails?.firstname}{" "}
          {nurseData?.agentDetails?.lastname}
        </p>
        <img src={assets.downArrow} alt="merago" />
      </div>
      {open && (
        <div className="sidebar__conatiner agent__sidebar">
          {location.pathname !== "/agent" && (
            <button onClick={handleLogout}>Logout</button>
          )}
        </div>
      )}
    </div>
  ) : null;
});

export default withOutsideClickNurse(NurseMenu);
