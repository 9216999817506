import React from "react";
import { useLocation } from "react-router-dom";
import ProviderMobileMenu from "./ProviderMobileMenu";
import NurseMobileMenu from "./NurseMobileMenu";
import AgentMobilemenu from "./AgentMobilemenu";

const MobileMenuList = () => {
  const location = useLocation();
  return (
    <>
      {location?.pathname.includes("agent") ? (
        <AgentMobilemenu />
      ) : location?.pathname.includes("nurse") ? (
        <NurseMobileMenu />
      ) : (
        <ProviderMobileMenu />
      )}
    </>
  );
};

export default MobileMenuList;
