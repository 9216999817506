import nurseAPIClient from "./nurseAPIClient";
import { toast } from "react-toastify";

const request = {
  get: (url, params) =>
    nurseAPIClient
      .get(url, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          // Handle unauthorized error
          // localStorage.clear();
          // window.location = "/login";
        } else if (res?.response?.status === 500) {
          toast.error(
            "Oops something went wrong, please try again after sometime or contact Merago Support"
          );
        }
      }),
  post: (url, data, params) =>
    nurseAPIClient
      .post(url, data, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          // Handle unauthorized error
          // localStorage.clear();
          // window.location = "/login";
        } else if (res?.message === "Network Error") {
          window.location = "/maintainence";
        }
      }),
  put: (url, data, params) =>
    nurseAPIClient
      .put(url, data, { params })
      .then((res) => res.data)
      .catch((res) => {
        if (res?.response?.status === 401) {
          // localStorage.clear();
          window.location = "/agent";
        } else if (res?.message === "Network Error") {
          window.location = "/maintainence";
        }
      }),
};

//! 14-API
//?1. Alert - Nurse
export const getAgentAlertWithFilterNurse = (data) =>
  request.post("/merago/api/provider/agent/v1/firstAlertNotifications", data);
//? 1.Nurse Urgent Care request
export const urgentCareNurseRequests = (data) =>
  request.post("/merago/api/provider/agent/v1/urgentCareNurseRequests", data);

//* 2.Get Shared Records
export const getSharedRecordsNurse = (params) =>
  request.get("/merago/api/provider/getSharedDocuments", params);
//* 3.Get COD Details
export const getCODDetails = (params) =>
  request.get("/merago/api/provider/urgentcare/details", params);
//* 4.Get Profile Values
export const getProfileValues = (params) =>
  request.get("/merago/api/customer/getProfileValues", params);
//* 5.Get Profile DP
export const getProfileDP = (params) =>
  request.post("/merago/api/storage/getDPURL", "", params);
//? 6.Start Service By Agent
export const nurseJoinConsultation = (data) =>
  request.post("/merago/api/provider/urgentCareConsultation/join", data);
//* 7.Get Consultations
export const getConsultations = (params) =>
  request.get("/merago/api/provider/getConsultationForProvider", params);
//?8. Alert - Nurse
export const updateUrgentCareNurseComments = (data) =>
  request.put("/merago/api/provider/v1/updateUrgentCareNurseComments", data);
//* 9.Get Agent Details
export const getAgentByUserId = (params) =>
  request.get("/merago/api/provider/agent/v1/agentById", params);
//* 10.Get Consultation Messages
export const getConsultationMessages = (params) =>
  request.get("/merago/api/msg/getConsultationMessagesV2", params);
//? 11.Send Consultation Message
export const sendConsultationMessage = (data) =>
  request.post("/merago/api/msg/sendConsultationMessage", data);
//? 12.talk to nurse dashboard
export const talkToNurseRequests = (data) =>
  request.post("/merago/api/provider/agent/v1/talkToNurseRequests", data);
//? 13.Get Case Notes URL - Post
export const caseNotesURLV2 = (params) =>
  request.post("/merago/api/document/v2/getDocuementURLByID", "", params);
//? 14.Get Documents by ID new
export const getDocsById = (params) =>
  request.post("/merago/api/document/v2/getDocuementByID", "", params);
//? 15.Upload Consultation Document
export const uploadNurseDoc = (data) =>
  request.post("/merago/api/provider/ConsultationDocument", data);
//* 16.Get Nurse Uploaded Docs
export const getNurseUploadDocs = (params) =>
  request.get("/merago/api/provider/ConsultationDocument", params);
//?17. Alert - Update Document CaptureS tatus
export const updateDocumentCaptureStatus = (params) =>
  request.put(
    "/merago/api/provider/v1/updateDocumentCaptureStatus",
    "",
    params
  );
//* 18.Get Dashbaord Menu Status
export const getDashboardMenuStatus = (params) =>
  request.get("/merago/api/provider/agent/v1/dashboardMenuStatus", params);
